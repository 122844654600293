import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template


const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
//   let postCounter = 0

  return (
    <Layout title={siteTitle}>
      <Seo
        title="Blog"
        keywords={[`blog`, `ui`, `ux`, `javascript`, `front-end`, `design`, `arduino`, `diy`]}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            Conheça alguns projetos que realizei e ajudei a realizar.
          </h2>
        </header>
      )}

    <div className="post-feed">
        {posts.map(({ node }) => {
        //   postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              contentSlug = {`blog`}
              count={1}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>

    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
        limit: 4,
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fields: {collection: {eq: "blog"}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
            collection
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            status
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
    <StaticQuery
      query={indexQuery}
      render={data => (
        <BlogIndex location={props.location} props data={data} {...props} />
      )}
    />
)
